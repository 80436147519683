<style type="text/css">
@import '~@/assets/css/common.css';
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.custom-file-label {
  padding-top: 4px;
}
/deep/ .el-upload--text {
  width: 100% !important;
}
</style>
<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import PersonHeader from '@/views/pages/examinee/person-header.vue'
import handleImgItem from '@/views/pages/examinee/comp/handleImgItem.vue'
import { getStandardPhoto } from '@/api/userInfo.js'
import twoImgView from '@/views/pages/examinee/comp/twoImgView.vue'
import { getUserInfo } from '@/libs/common'
import { authMethods } from '@/state/helpers'
import { userInfo } from '../../../api/userInfo'

/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
    handleImgItem,
    twoImgView
  },
  data() {
    return {
      title: '个人资料完善',
      items: [
        {
          text: '资料完善'
        },
        {
          text: '照片信息',
          active: true
        }
      ],
      page: 'img',
      show: '',
      fileType: '',
      userPhoto: {},
      userInfo: {},
      disable: false
    }
  },
  methods: {
    ...authMethods,
    getUserPhoto() {
      getStandardPhoto().then((res) => {
        if (res.status) {
          if (res.data) {
            this.userPhoto = res.data
            this.logOut()
          }
        }
      })
    },
    next() {
      if (this.disable) {
        this.$confirm(
          '白底证件照系统审核未通过, 需要上传身份证扫描件',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        return
      }
      this.$router.push('./personalEducation')
    },
    showTwoImg() {
      this.$refs.twoImgView.show(
        this.userPhoto.bzz,
        this.userPhoto.xbzz,
        this.userPhoto.xbzzshzt
      )
    }
  },
  created() {
    let show = this.$route.query.show
    if (show) {
      this.show = show
    }
    let _this = this
    this.getUserPhoto()
    getUserInfo().then((data) => {
      if (data) {
        _this.userInfo = data
      }
    })
  },
  watch: {
    userPhoto: {
      deep: true,
      handler(obj) {
        if (
          this.userPhoto.bzz &&
          (this.userPhoto.bzzshzt
            ? this.userPhoto.bzzshzt
            : this.userPhoto.bzzxthyzt) == 2 &&
          (!this.userPhoto.sfzsmjrxm || !this.userPhoto.sfzsmjghm)
        ) {
          this.disable = true
        } else {
          this.disable = false
        }
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" :show="show" :disable="disable" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <!-- <span class="mr-4">照片及身份证件认证</span> -->
              <span class="mr-2">
                <i class="iconfont icon-ze-user-o mr-2"></i>
                姓名
              </span>
              <span class="mr-2">{{ userInfo.xm }}</span>
              <span class="mr-2">
                <i class="iconfont icon-idcard1e mr-2"></i>
                居民身份证/社会保障卡
              </span>
              <span class="mr-2">{{ userInfo.sfzjh }}</span>
            </h4>
            <div>
              <h4 class="card-title ml-3 mt-3">
                上传标准证件照
                <a
                  :href="FILE_URL + '/public/util/照片审核处理工具.rar'"
                  target="_bank"
                  class="ml-3"
                  style="text-decoration: underline !important"
                >
                  下载照片审核处理工具
                </a>
              </h4>
              <div class="d-flex p-3">
                <handleImgItem
                  title="标准证件照"
                  :must="true"
                  :imgId="userPhoto.bzz"
                  des="照片处理工具（点击下载）"
                  :href="FILE_URL + '/public/util/照片审核处理工具.rar'"
                  :shzt="
                    userPhoto.bzzshzt ? userPhoto.bzzshzt : userPhoto.bzzxthyzt
                  "
                  :shsm="
                    userPhoto.bzzshzt ? userPhoto.bzzshsm : userPhoto.bzzxthysm
                  "
                  :type="1"
                  :sfts="
                    (userPhoto.bzzshzt
                      ? userPhoto.bzzshzt
                      : userPhoto.bzzxthyzt) == 1
                  "
                  module="UserPhoto"
                  :sfpdx="true"
                  :sid="userPhoto.sid"
                  :sfxslzzp="!!userPhoto.xbzz"
                  @showTwoImg="showTwoImg"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  v-if="userInfo.checkUserPhotoIdentity == 'true'"
                  title="非必须，不推荐（蓝底）"
                  :imgId="userPhoto.ldz"
                  des="尺寸为宽295px，高为413px，检测是否符合要求请下载照片处理工具。"
                  :shzt="
                    userPhoto.ldzshzt ? userPhoto.ldzshzt : userPhoto.ldzxthyzt
                  "
                  :shsm="
                    userPhoto.ldzshzt ? userPhoto.ldzshsm : userPhoto.ldzxthysm
                  "
                  :type="2"
                  :sfts="false"
                  module="UserPhoto"
                  :sid="userPhoto.sid"
                  :sfpdx="true"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  v-if="userInfo.checkUserPhotoIdentity == 'true'"
                  title="非必须，不推荐（红底）"
                  :imgId="userPhoto.hdz"
                  des="尺寸为宽295px，高为413px，检测是否符合要求请下载照片处理工具。"
                  :shzt="
                    userPhoto.hdzshzt ? userPhoto.hdzshzt : userPhoto.hdzxthyzt
                  "
                  :shsm="
                    userPhoto.hdzshzt ? userPhoto.hdzshsm : userPhoto.hdzxthysm
                  "
                  :type="3"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserPhoto"
                  :sfpdx="true"
                  @success="getUserPhoto"
                ></handleImgItem>
              </div>
            </div>
            <div
              v-if="
                (userPhoto.bzzshzt ? userPhoto.bzzshzt : userPhoto.bzzxthyzt) ==
                  2 && userInfo.checkUserPhotoIdentity == 'true'
              "
            >
              <h4 class="card-title ml-3 mt-3">
                白底证件照审核未通过, 需要上传身份证扫描件
              </h4>
              <div class="d-flex p-3">
                <handleImgItem
                  title="身份证扫描件（人像面）"
                  :imgId="userPhoto.sfzsmjrxm"
                  :shzt="userPhoto.zjshzt ? userPhoto.zjshzt : '0'"
                  :shsm="
                    userPhoto.zjshzt ? userPhoto.zjshbz : '已上传，等待人工审核'
                  "
                  :type="4"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserIdPhoto"
                  :sfpdx="false"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  title="身份证扫描件（国徽面）"
                  :imgId="userPhoto.sfzsmjghm"
                  :shzt="userPhoto.zjshzt ? userPhoto.zjshzt : '0'"
                  :shsm="
                    userPhoto.zjshzt ? userPhoto.zjshbz : '已上传，等待人工审核'
                  "
                  :type="5"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserIdPhoto"
                  :sfpdx="false"
                  @success="getUserPhoto"
                ></handleImgItem>
              </div>
            </div>
            <div class="d-flex p-3">
              <button
                type="button"
                class="btn btn-info h35 sub-btn"
                @click="next"
              >
                已上传，继续下一步
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <twoImgView ref="twoImgView"></twoImgView>
  </Layout>
</template>
